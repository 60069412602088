// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.glassmorphism {
  background-color: rgba(255, 255, 255, 0.15) !important;
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Aplicar desenfoque */
  border-radius: 8px; /* Bordes redondeados */
  border: 1px solid rgba(255, 255, 255, 0.15); /* Borde translúcido */
  transition: all 0.3s ease !important; /* Transición suave */
  color: white !important;
}

/* .glassmorphism:hover {
  transform: scale(1.05); 
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); 
/* } */

.bottom-center-close {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.list-patient {
  background-color: red;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blink {
  animation: blink 1s infinite;
}

.title-medic {
  -webkit-text-decoration: 'underline';
          text-decoration: 'underline'
}`, "",{"version":3,"sources":["webpack://./src/Pages/Consultorios.css"],"names":[],"mappings":"AAAA;EACE,sDAAsD;EACtD,mCAA2B;UAA3B,2BAA2B,EAAE,uBAAuB;EACpD,kBAAkB,EAAE,uBAAuB;EAC3C,2CAA2C,EAAE,sBAAsB;EACnE,oCAAoC,EAAE,qBAAqB;EAC3D,uBAAuB;AACzB;;AAEA;;;MAGM;;AAEN;EACE,eAAe;EACf,YAAY;EACZ,SAAS;EACT,2BAA2B;AAC7B;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE;IACE,UAAU;EACZ;EACA;IACE,YAAY;EACd;EACA;IACE,UAAU;EACZ;AACF;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,oCAA2B;UAA3B;AACF","sourcesContent":[".glassmorphism {\n  background-color: rgba(255, 255, 255, 0.15) !important;\n  backdrop-filter: blur(10px); /* Aplicar desenfoque */\n  border-radius: 8px; /* Bordes redondeados */\n  border: 1px solid rgba(255, 255, 255, 0.15); /* Borde translúcido */\n  transition: all 0.3s ease !important; /* Transición suave */\n  color: white !important;\n}\n\n/* .glassmorphism:hover {\n  transform: scale(1.05); \n  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.1); \n/* } */\n\n.bottom-center-close {\n  position: fixed;\n  bottom: 20px;\n  left: 50%;\n  transform: translateX(-50%);\n}\n\n.list-patient {\n  background-color: red;\n}\n\n@keyframes blink {\n  0% {\n    opacity: 1;\n  }\n  50% {\n    opacity: 0.5;\n  }\n  100% {\n    opacity: 1;\n  }\n}\n\n.blink {\n  animation: blink 1s infinite;\n}\n\n.title-medic {\n  text-decoration: 'underline'\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
