import React, { useState, useEffect } from "react";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
// import DoctorList from "./components/DoctorList";
// import PatientList from "./components/PatientList";
import Header from "../components/Header";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Grid, Card, Dialog, CardHeader, CardContent, Avatar, Typography, Snackbar, Backdrop, CircularProgress, Button, Chip } from '@mui/material';
import bgImage from './images/bg-servicios.jpg';
import './Consultorios.css'
import DataStorage from "../components/DataStorage";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';

const Consultorios = () => {
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [casatvData, setCasatvData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [firstCycle, setFirstCycle] = useState(true);
  const [doctorsData, setDoctorsData] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [specialitySelect, setspecialitySelect] = useState();
  const [listEspecialidadesUnicas, setListEspecialidadesUnicas] = useState([]);
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [medicosFiltrados, setMedicosFiltrados] = useState([]);
  const [tiempoRestante, setTiempoRestante] = useState(30);
  const [mostrarSnackbar, setMostrarSnackbar] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [consultoriosSinRepeticiones, setConsultoriosSinRepeticiones] = useState([]);
  const [consultoriosAMostrar, setConsultoriosAMostrar] = useState([]);
  const [columnSize, setColumnSize] = useState(1);

  const [showDialog, setShowDialog] = useState(false);


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const fechaActual = new Date();
  const diaActual = diasSemana[fechaActual.getDay()];


  const removeData = (data) => {
    const filteredData = data.filter(item => {
      return item.OA !== 'SN' || item.Paciente.includes(diaActual);
    });

    return filteredData;
  };

  const loadData = () => {
    setOpenLoading(true)
    setSelectedEspecialidad(null);
    axios.get('https://tvs-api.chatup.pe/api/casatv')
      .then(async (response) => {
        const config = JSON.parse(localStorage.getItem('config'));
        const dataCitas = response.data;


        if (!config) {
          // const data = removeData(response.data)
          await calcularConsultoriosSinRepeticiones(dataCitas);
          setShowDialog(true);
        } else {
          const { consultoriosSeleccionados } = config;
          setColumnSize(Math.floor(12 / consultoriosSeleccionados.length))
          const consultoriosTransformados = consultoriosSeleccionados.map(transformarTexto);
          // setConsultoriosAMostrar(consultoriosTransformados);
          const dataCitasFormateded = await agruparCitasPorConsultorio(dataCitas, consultoriosSeleccionados);
          setConsultoriosAMostrar(dataCitasFormateded)
        }



        console.log('DATA:', response.data);
        // encontrarEspecialidadesUnicas(data);
        // setCasatvData(data);
        // obtenerTodosLosMedicos(data);
        setOpenLoading(false)
      })
      .catch((error) => {
        setOpenLoading(false)
        console.error('Error al obtener los datos de casatv:', error);
      });

  }

  const transformarTexto = (texto) => {
    const sinGuiones = texto.replace(/-/g, '').toUpperCase();
    let transformado = sinGuiones;
    if (transformado.includes(' ')) {
      transformado = transformado.split(' ').map(word => word.charAt(0)).join('');
    } else {
      if (transformado.length > 4) {
        transformado = `${transformado.charAt(0)}${transformado.charAt(1)}${transformado.charAt(2)}`;
      }
    }
    return transformado;
  }

  // Calcular el tamaño de la columna xs automáticamente


  const calcularConsultoriosSinRepeticiones = (data) => {
    const consultoriosUnicos = new Set();
    data.forEach(cita => {
      consultoriosUnicos.add(cita.Consultorio);
    });

    setConsultoriosSinRepeticiones([...consultoriosUnicos]);
  };

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleReset = () => {
    localStorage.removeItem('config');
    setShowDialog(true);
  };

  const agruparCitasPorConsultorio = (citas, consultoriosSeleccionados) => {
    // Filtramos las citas por los consultorios seleccionados
    const citasFiltradas = citas.filter(cita => consultoriosSeleccionados.includes(cita.Consultorio));

    // Creamos un objeto para almacenar temporalmente las citas por consultorio
    const citasPorConsultorio = {};

    // Agrupamos las citas por consultorio
    citasFiltradas.forEach(cita => {
      if (!citasPorConsultorio[cita.Consultorio]) {
        citasPorConsultorio[cita.Consultorio] = [];
      }
      citasPorConsultorio[cita.Consultorio].push(cita);
    });

    // Creamos el array de objetos final con la estructura deseada
    const resultado = Object.entries(citasPorConsultorio).map(([consultorio, citas]) => {
      return {
        shortName: transformarTexto(consultorio),
        IdConsultorio: citas[0].IdConsultorio,
        Consultorio: consultorio,
        Especialidad: citas[0].Especialidad,
        Medico: citas[0].Medico,
        pacientes: citas.map(cita => {
          return {
            IdOrdenAtencion: cita.IdOrdenAtencion,
            OA: cita.OA,
            EstadoDocumento: cita?.EstadoDocumento,
            Paciente: cita.Paciente,
            IdCita: cita.IdCita,
            FechaCita: cita.FechaCita
          };
        })
      };
    });
    return resultado;
  }

  const hiddenOA = (numero) => {
    // Convertir el número a cadena
    let numeroComoCadena = numero.toString();

    // Obtener los últimos tres caracteres usando substring
    let ultimosTresNumeros = numeroComoCadena.substring(numeroComoCadena.length - 3);

    return ultimosTresNumeros;
  }

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh", backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>

      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DataStorage consultorios={consultoriosSinRepeticiones} onClose={handleCloseDialog} />
      </Dialog>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Snackbar
        open={mostrarSnackbar && tiempoRestante > 0}
        autoHideDuration={30000}
        onClose={() => setMostrarSnackbar(false)}
        message={`En ${tiempoRestante} seg.regresaremos a la pantalla aneterior.`}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Header selectedDoctor={selectedDoctor} title="ATENCIÓN POR CONSULTORIO" />
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 0.5 }}
        spacing={0.5}>




        <Grid container spacing={1}>
          {consultoriosAMostrar?.map((consultorio, index) => (
            <Grid key={index} item xs={columnSize}>
              <Card className="glassmorphism">
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: 'red', padding: '7px' }} >{consultorio?.shortName}</Avatar>
                  }
                  title={<Typography variant="h6" className="title-medic">{consultorio?.Medico}</Typography>}
                  subheader={<Typography variant="h6">{consultorio?.Especialidad}</Typography>}
                />
                <CardContent>

                  <List sx={{ width: '100%' }}>
                    {consultorio?.pacientes?.filter(paciente => paciente.OA !== "SN" && paciente?.EstadoDocumento === "ATENCION").slice(0, 2).map((paciente, index) => (
                      <ListItem key={index} disableGutters secondaryAction={
                        <IconButton aria-label="comment">
                          {
                            <Chip
                              color="success"
                              className="blink"
                              label="ATENDIENDO"
                              avatar={<MedicalServicesIcon />}
                            />
                          }
                        </IconButton>
                      }>
                        <ListItemText
                          primary={paciente.Paciente}
                        />
                      </ListItem>
                    ))}

                    {consultorio?.pacientes?.filter(paciente => paciente.OA !== "SN" && paciente?.EstadoDocumento !== "ATENDIDO" && paciente?.EstadoDocumento !== "ATENCION").map((paciente, index) => (
                      <ListItem key={index} disableGutters secondaryAction={
                        <IconButton aria-label="comment">
                          {
                            index === 0 ?
                              <Chip
                                color="secondary"
                                className="blink"
                                label="Llamando..."
                                avatar={<VolumeUpIcon />}
                              />
                              :
                              <Chip
                                color="info"
                                label={paciente?.EstadoDocumento}

                              />
                          }
                        </IconButton>
                      }>
                        <ListItemText
                          primary={paciente.Paciente}
                        // secondary={<Typography variant="overline" display="block">{`OA: ****${hiddenOA(paciente.OA)}`}</Typography>}
                        />
                      </ListItem>
                    ))}
                  </List>

                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div className="bottom-center-close">
          <Button onClick={handleReset} variant="contained" >Resetear</Button>
        </div>
      </Stack>
    </div>
  );
};

export default Consultorios;
