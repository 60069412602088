import React, { useState, useEffect } from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";
import moment from 'moment';
import 'moment/locale/es'; // Importa la configuración regional en español
import logo from './LOGO.png';
import bgImage from './bg-servicios.jpg';
import './Header.css'

moment.locale('es');
const Header = ({ selectedDoctor, title }) => {
  const [currentTime, setCurrentTime] = useState(getCurrentTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(getCurrentTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  function getCurrentTime() {
    const now = new Date();
    const hours = now.getHours();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();
    return `${hours.toString().padStart(2, "0")}:${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  const isTvView = window.innerWidth < 1050 && window.innerWidth > 769;

  return (
    <AppBar position="static" style={{ minHeight: '100px', paddingTop: '15px', backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
      <Toolbar>
        <Typography variant="h4" component="div">
          <img src={logo} height={'50px'} alt="logo" />
        </Typography>
        <Typography variant="h3" component="div" sx={{ flexGrow: 2 }}>
          <div style={{ textAlign: "center" }} >
            {selectedDoctor?.SPECIALTY}
            <Typography variant="h4" component="div" sx={{ flexGrow: 1 }} >
              {title ? title : 'Médicos disponibles para hoy'}
            </Typography>
          </div>
        </Typography>
        <div>
          <Typography variant="h4">
            <div>{currentTime}</div>
            <div style={{ fontSize: "1.3rem" }}>{moment().format('ddd DD MMM')}</div>
          </Typography>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
