import React from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#004178",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  fontSize: "1.3rem",
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
  [theme.breakpoints.down("1050")]: {
    fontSize: "0.6rem",
  },
  [theme.breakpoints.down("769")]: {
    fontSize: "0.8rem",
    fontWeight: "normal"
  },
}));

const StyledTableDetail = styled(TableCell)(({ theme }) => ({
  color: "black",
  fontSize: "0.95rem",
  fontFamily: "Arial, sans-serif",
  textAlign: "center",
  [theme.breakpoints.down("1050")]: {
    fontSize: "0.8rem"
  },
  [theme.breakpoints.down("769")]: {
    fontSize: "0.8rem",
    fontWeight: "normal"
  },
}));

const PatientList = ({ patients, selectedDoctor, }) => {
  return (
    <>
{patients.filter(data => data?.EstadoDocumento !== "1").length > 0 &&
      <TableContainer component={Paper} style={{paddingBottom: "7px"}} >
        <Table aria-label="doctor table">
          <StyledTableHead>
            <TableRow>
              <StyledTableCell>TURNO</StyledTableCell>
              <StyledTableCell>NOMBRE DE PACIENTE</StyledTableCell>
              <StyledTableCell>ESTADO</StyledTableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {patients.filter(data => data?.EstadoDocumento !== "1").map((patient, index) => (
              <TableRow key={index}>
                <StyledTableDetail>{index + 1}</StyledTableDetail>
                <StyledTableDetail>{patient?.Paciente}</StyledTableDetail>
                <StyledTableDetail>{patient?.EstadoDocumento}</StyledTableDetail>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
}
{/* 
{patients.filter(data => data?.EstadoDocumento === "1").length > 0 &&
      <TableContainer component={Paper} >
        <Table aria-label="doctor table">
          
          <StyledTableHead>
            
              <StyledTableCell>HORARIO DE ATENCIÓN</StyledTableCell>
            
          </StyledTableHead>
          <TableBody>
            {patients.filter(data => data?.EstadoDocumento === "1").map((patient, index) => (
              <TableRow key={index}>
                <StyledTableDetail style={{fontSize:"1.4rem",  textAlign: "center"}}>
                  {patient?.Paciente}
                  </StyledTableDetail>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
} */}
    </>
  );
};

export default PatientList;
