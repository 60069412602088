import React, { useState, useEffect, useRef } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import moment from "moment";
import "./DoctorList.css";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: "#004178",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  fontSize: "1rem",
  fontFamily: "Arial, sans-serif",
  backgroundColor: "#22598a",
  textAlign: "center",
  [theme.breakpoints.down("1050")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("769")]: {
    fontSize: "0.8rem",
    fontWeight: "normal"
  },
}));

const StyledTableDetail = styled(TableCell)(({ theme }) => ({
  color: "black",
  fontWeight: "bold",
  fontSize: "1.5rem",
  fontFamily: "Arial, sans-serif",
  [theme.breakpoints.down("1050")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("769")]: {
    fontSize: "1rem",
    fontWeight: "normal"
  },
}));

const StyledTableDetailConsultorio = styled(TableCell)(({ theme }) => ({
  color: "black",
  fontSize: "1rem",
  fontFamily: "Arial, sans-serif",
  [theme.breakpoints.down("1050")]: {
    width: '80px',
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("769")]: {
    width: '80px',
    fontSize: "0.8rem",
    fontWeight: "normal"
  },
}));

const StyledTableAttention = styled(TableCell)(({ theme }) => ({
  color: "black",
  fontWeight: "bold",
  fontSize: "1rem",
  fontFamily: "Arial, sans-serif",
  [theme.breakpoints.down("1050")]: {
    fontSize: "0.3rem",
    lineHeight: "0.6rem",
    fontWeight: "normal"
  },
  [theme.breakpoints.down("769")]: {
    fontSize: "0.6rem",
    lineHeight: "0.5rem",
    fontWeight: "normal"
  },
  paddingBottom: "0",
  paddingTop: "0"
}));

const HighlightedTableRow = styled(TableRow)(({ theme, isSelected }) => ({
  backgroundColor: isSelected ? "#FFD700" : "",
  cursor: "pointer",
  padding: "10px !important"
}));

const DoctorList = ({ doctors, selectedDoctor, setSelectedDoctor, schedulDoctor, selectedEspecialidad }) => {
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const tableRef = useRef(null);
  const [scrollInterval, setScrollInterval] = useState(2000); // Initial scroll interval: 2 seconds

  useEffect(() => {
    // Calculate the page where the selected doctor is located
    const selectedDoctorIndex = doctors.findIndex(doctor => doctor?.DOCTOR === selectedDoctor.DOCTOR);
    const newPage = Math.floor(selectedDoctorIndex / rowsPerPage);
    setPage(newPage);
  }, [selectedDoctor, rowsPerPage]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (tableRef.current) {
        const currentScroll = tableRef.current.scrollTop;
        const rowHeight = tableRef.current.querySelector('tr').offsetHeight;
        const maxScroll = tableRef.current.scrollHeight - tableRef.current.clientHeight;
        if (currentScroll + tableRef.current.clientHeight >= maxScroll) {
          tableRef.current.scrollTo({
            top: 0,
            behavior: 'smooth',
          });
        } else {
          tableRef.current.scrollTo({
            top: currentScroll + rowHeight,
            behavior: 'smooth',
          });
        }
      }
    }, scrollInterval); // Use scrollInterval state variable here

    return () => clearInterval(interval);
  }, [scrollInterval]); // Listen for changes in scrollInterval

  const handleRowClick = (doctorId) => {
    setSelectedDoctor(doctorId);
    // Calculate and update the page based on the selected doctor
    const newSelectedDoctorIndex = doctors.findIndex(doctor => doctor.DOCTOR === doctorId);
    const newPage = Math.floor(newSelectedDoctorIndex / rowsPerPage);
    setPage(newPage);
  };

  const handleTouchStart = () => {
    setScrollInterval(40000); // Change scroll interval to 40 seconds when touch starts
  };

  const handleTouchEnd = () => {
    setScrollInterval(2000); // Reset scroll interval to 2 seconds when touch ends
  };

  const isTvView = window.innerWidth > 721;
  return (
    <Stack sx={{ overflowY: 'auto' }} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
      <Typography variant="h4" className="headerList">MEDICOS {selectedEspecialidad || ''}</Typography>
      <TableContainer component={Paper} ref={tableRef}>
        <Table aria-label="doctor table">
          <TableBody>
            {doctors
              .map((doctor) => (
                <HighlightedTableRow
                  key={doctor.DOCTOR}
                  isSelected={selectedDoctor.DOCTOR === doctor.DOCTOR}
                >
                  <StyledTableDetail>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <div>{doctor?.DOCTOR}</div>
                      <div>{selectedEspecialidad === null && doctor?.SPECIALTY}</div>
                    </Stack>
                    <div style={{ fontSize: "1.2rem", color: "blue" }}>
                      Atiende {doctor?.SCHEDULE}
                    </div>
                  </StyledTableDetail>
                </HighlightedTableRow>
              ))}
          </TableBody>
        </Table>
        {/* {!isTvView && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 15]}
            component="div"
            count={doctors.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(event, newPage) => setPage(newPage)}
            onRowsPerPageChange={(event) => {
              setRowsPerPage(parseInt(event.target.value, 5));
              setPage(0);
            }}
          />
        )} */}
      </TableContainer>
    </Stack>
  );
};

export default DoctorList;
