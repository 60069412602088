// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes zoomEffect {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

.zoomCell {
  animation: zoomEffect 3s infinite;
}

.headerList {
  background-color: #22598a;
    color: white;
    padding: 2px;
    display: flex;
    justify-content: center;
}

/* Media query para resolución 1024x600 */
@media (max-width: 1024px) and (max-height: 600px) {
  .zoomCell {
    animation: none; /* Detener la animación */
    font-size: 0.8rem; /* Ajustar el tamaño de la fuente */
  }

  /* Ajustar el tamaño de fuente en StyledTableDetail */
  .zoomCell .StyledTableDetail {
    font-size: 0.8rem; /* Ajustar el tamaño de la fuente para StyledTableDetail */
    color: red;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/DoctorList.css"],"names":[],"mappings":"AAAA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,qBAAqB;EACvB;AACF;;AAEA;EACE,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;IACvB,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,uBAAuB;AAC3B;;AAEA,yCAAyC;AACzC;EACE;IACE,eAAe,EAAE,yBAAyB;IAC1C,iBAAiB,EAAE,mCAAmC;EACxD;;EAEA,qDAAqD;EACrD;IACE,iBAAiB,EAAE,0DAA0D;IAC7E,UAAU;EACZ;AACF","sourcesContent":["@keyframes zoomEffect {\n  0%, 100% {\n    transform: scale(1);\n  }\n  50% {\n    transform: scale(1.1);\n  }\n}\n\n.zoomCell {\n  animation: zoomEffect 3s infinite;\n}\n\n.headerList {\n  background-color: #22598a;\n    color: white;\n    padding: 2px;\n    display: flex;\n    justify-content: center;\n}\n\n/* Media query para resolución 1024x600 */\n@media (max-width: 1024px) and (max-height: 600px) {\n  .zoomCell {\n    animation: none; /* Detener la animación */\n    font-size: 0.8rem; /* Ajustar el tamaño de la fuente */\n  }\n\n  /* Ajustar el tamaño de fuente en StyledTableDetail */\n  .zoomCell .StyledTableDetail {\n    font-size: 0.8rem; /* Ajustar el tamaño de la fuente para StyledTableDetail */\n    color: red;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
