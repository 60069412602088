import React, { useState, useEffect } from "react";
import { Grid } from "@mui/material";
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import DoctorList from "./components/DoctorList";
import PatientList from "./components/PatientList";
import Header from "./components/Header";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { Select, MenuItem, FormControl, Typography, Snackbar, Backdrop, CircularProgress } from '@mui/material';

const App = () => {
  const [selectedDoctor, setSelectedDoctor] = useState([]);
  const [casatvData, setCasatvData] = useState([]);
  const [progress, setProgress] = useState(0);
  const [firstCycle, setFirstCycle] = useState(true);
  const [doctorsData, setDoctorsData] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [specialitySelect, setspecialitySelect] = useState();
  const [listEspecialidadesUnicas, setListEspecialidadesUnicas] = useState([]);
  const [selectedEspecialidad, setSelectedEspecialidad] = useState(null);
  const [medicosFiltrados, setMedicosFiltrados] = useState([]);
  const [tiempoRestante, setTiempoRestante] = useState(30);
  const [mostrarSnackbar, setMostrarSnackbar] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [timer, setTimer] = useState(null);


  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 0,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
  }));

  const diasSemana = ["Domingo", "Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado"];
  const fechaActual = new Date();
  const diaActual = diasSemana[fechaActual.getDay()];
  const iniciarContador = () => {
    let contador = 30;
    const intervalo = setInterval(() => {
      contador--;
      setTiempoRestante(contador);
      setMostrarSnackbar(true);
      if (contador === 0) {
        clearInterval(intervalo);
      }
    }, 1000);
  };


  // Función para obtener todos los médicos sin repetición
  const obtenerTodosLosMedicos = (data) => {
    const medicosSet = new Set();
    data.forEach(elemento => {
      medicosSet.add(elemento.Medico);
    });

    // Convertir el conjunto en un array
    const medicosArray = Array.from(medicosSet);

    // Crear objetos médicos con otros detalles


    const medicosDetallados = medicosArray.map(medico => {
      const doctorRecords = data.filter(item => item.Medico === medico);

      return {
        DOCTOR: medico,
        SPECIALTY: doctorRecords.length > 0 ? doctorRecords[0].Especialidad : '', // Obtener especialidad del primer registro
        OFFICE: doctorRecords.length > 0 ? doctorRecords[0].Consultorio : '',    // Obtener consultorio del primer registro
        SCHEDULE: ToSchedule(doctorRecords) // Llamar función para generar el horario
      };
    });
    setMedicosFiltrados(medicosDetallados);
    setDoctorsData(medicosDetallados)

  };


  const encontrarEspecialidadesUnicas = (data) => {
    const especialidadesUnicas = {};
    data.forEach(elemento => {
      const especialidad = elemento.Especialidad;
      especialidadesUnicas[especialidad] = true;
    });
    const listaEspecialidades = Object.keys(especialidadesUnicas);
    setListEspecialidadesUnicas(listaEspecialidades);
  };

  const removeData = (data) => {
    const filteredData = data.filter(item => {
      return item.OA !== 'SN' || item.Paciente.includes(diaActual);
    });

    return filteredData;
  };

  const loadData = () => {
    setOpenLoading(true)
    setSelectedEspecialidad(null);
    axios.get('https://tvs-api.chatup.pe/api/casatv')
      .then((response) => {
        const data = removeData(response.data)
        encontrarEspecialidadesUnicas(data);
        setCasatvData(data);
        obtenerTodosLosMedicos(data);
        setOpenLoading(false)
      })
      .catch((error) => {
        setOpenLoading(false)
        console.error('Error al obtener los datos de casatv:', error);
      });
  }

  useEffect(() => {
    loadData()
  }, []);

  // useEffect(() => {
  //   if (selectedDoctor !== null) {
  //     setspecialitySelect(selectedDoctor?.SPECIALTY)
  //     const patientsForSelectedDoctor = casatvData.filter(
  //       (data) => data.Medico === selectedDoctor.DOCTOR
  //     );
  //     setSelectedPatients(patientsForSelectedDoctor ? patientsForSelectedDoctor : []);
  //   }
  // }, [selectedDoctor]);


  const ToSchedule = (schedule) => {
    if (selectedPatients) {
      const horario = schedule.find(data => data?.EstadoDocumento === "1" && data?.Paciente.includes(diaActual));
      if (horario) {
        const horarioSinComas = horario.Paciente.replace(/,/g, ''); // Eliminar comas
        return `Hoy de ${horarioSinComas.replace(/Domingo|Lunes|Martes|Miércoles|Jueves|Viernes|Sábado/g, '').trim()}`;
      } else {
        return `Hoy ${diaActual} `
      }
    }
  };

  function filterAndSortDoctors(data) {
    const uniqueDoctors = [...new Set(data.sort((a, b) => a.Especialidad.localeCompare(b.Especialidad)).map((item) => item.Medico))];
    // console.log('uniqueDoctors:', uniqueDoctors);
    const result = uniqueDoctors.map((doctor) => {
      const doctorRecords = data.filter((item) => item.Medico === doctor);
      const specialty = doctorRecords[0].Especialidad;
      const office = doctorRecords[0].Consultorio;
      const schedule = ToSchedule(doctorRecords)

      return {
        DOCTOR: doctor,
        SPECIALTY: specialty,
        OFFICE: office,
        SCHEDULE: schedule
      };
    });
    if (firstCycle) {
      setSelectedDoctor(result[0])
    }
    return result;
  }

  // Manejar el cambio de la especialidad seleccionada
  const handleEspecialidadChange = (event) => {
    iniciarContador();
    const especialidadSeleccionada = event.target.value;
    setSelectedEspecialidad(especialidadSeleccionada);

    const medicosFiltradosSet = new Set();
    casatvData.forEach(elemento => {
      if (elemento.Especialidad === especialidadSeleccionada) {
        medicosFiltradosSet.add(elemento.Medico);
      }
    });

    const medicosFiltradosArray = Array.from(medicosFiltradosSet);

    const medicosDetallados = medicosFiltradosArray.map(medico => {
      const doctorRecords = casatvData.filter(item => item.Medico === medico);
      return {
        DOCTOR: medico,
        SPECIALTY: especialidadSeleccionada,
        OFFICE: doctorRecords[0].Consultorio,
        SCHEDULE: ToSchedule(doctorRecords)
      };
    });

    setMedicosFiltrados(medicosDetallados);
    setDoctorsData(medicosDetallados);

    // Cancelar el temporizador si ya se ha iniciado uno
    if (timer) {
      clearTimeout(timer);
    }

    // Volver a iniciar el temporizador
    const newTimer = setTimeout(loadData, 30000);
    setTimer(newTimer);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Snackbar
        open={mostrarSnackbar && tiempoRestante > 0}
        autoHideDuration={30000}
        onClose={() => setMostrarSnackbar(false)}
        message={`En ${tiempoRestante} seg. regresaremos a la pantalla aneterior.`}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Header selectedDoctor={selectedDoctor} />
      {/* <div style={{ marginBottom: "10px" }}>
        <BorderLinearProgress variant="determinate" value={progress} />
      </div> */}
      <Stack
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ p: 3 }}
        spacing={0.5}>
        <Typography variant="h5" >Especilidad: </Typography>
        <FormControl fullWidth>
          <Select
            value={selectedEspecialidad}
            onChange={handleEspecialidadChange}
            placeholder="Listado de especialidades"
          >
            {listEspecialidadesUnicas.map((especialidad, index) => (
              <MenuItem key={index} value={especialidad}>
                {especialidad}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      {doctorsData.length > 0 &&
        <DoctorList
          doctors={doctorsData}
          setSelectedDoctor={setSelectedDoctor}
          selectedDoctor={selectedDoctor}
          selectedEspecialidad={selectedEspecialidad}
        />
      }
      {/* <Grid item xs={12} sm={6}>
            <PatientList patients={selectedPatients} selectedDoctor={selectedDoctor} />
          </Grid> */}
    </div>
  );
};

export default App;
