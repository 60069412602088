// DialogComponent.js
import React, { useState } from 'react';
import './DataStorage.css'
import { Stack, Button } from '@mui/material';

function DataStorage({ consultorios, onClose }) {
  const [LocalStorageCantidadConsultorios, setLocalStorageCantidadConsultorios] = useState(1);
  const [LocalStorageEstilo, setLocalStorageEstilo] = useState('claro');
  const [LocalStorageConsultoriosSeleccionados, setLocalStorageConsultoriosSeleccionados] = useState([]);

  const handleChangeCantidad = (event) => {
    setLocalStorageCantidadConsultorios(parseInt(event.target.value));
  };

  const handleChangeEstilo = (event) => {
    setLocalStorageEstilo(event.target.value);
  };

  const handleChangeConsultorio = (index, event) => {
    const newConsultoriosSeleccionados = [...LocalStorageConsultoriosSeleccionados];
    newConsultoriosSeleccionados[index] = event.target.value;
    setLocalStorageConsultoriosSeleccionados(newConsultoriosSeleccionados);
  };

  const handleGuardar = () => {
    // Guardar la configuración en el localStorage
    const config = {
      cantidadConsultorios: LocalStorageCantidadConsultorios,
      consultoriosSeleccionados: LocalStorageConsultoriosSeleccionados,
      estilo: LocalStorageEstilo
    };
    localStorage.setItem('config', JSON.stringify(config));
    // Cerrar el diálogo
    onClose();
    window.location.reload();
  };

  return (
    <Stack className="dialog-container">
      <h2>PRIMERA CONFIGURACION EN ESTE EQUIPO</h2>
      <form>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}>
          <label htmlFor="cantidad" style={{ fontSize: '16px', paddingBottom: '12px' }}>¿Cuantos consultorios mostrará?</label>
          <input type="number" id="cantidad" value={LocalStorageCantidadConsultorios} onChange={handleChangeCantidad} />
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={1}>
          <label htmlFor="estilo" style={{ fontSize: '16px', paddingBottom: '12px' }}>Estilo</label>
          <select id="estilo" value={LocalStorageEstilo} onChange={handleChangeEstilo}>
            <option value="claro">Claro</option>
            <option value="oscuro">Oscuro</option>
          </select>
        </Stack>
        {Array.from({ length: LocalStorageCantidadConsultorios }, (_, index) => (
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={1}
            key={index}>
            <label htmlFor={`consultorio-${index}`} style={{ fontSize: '16px', paddingBottom: '12px' }}>Consultorio {index + 1}: </label>
            <select id={`consultorio-${index}`} value={LocalStorageConsultoriosSeleccionados[index] || ''} onChange={(e) => handleChangeConsultorio(index, e)}>
              <option value="">Seleccione un consultorio</option>
              {consultorios.map((consultorio, i) => (
                <option key={i} value={consultorio}>{consultorio}</option>
              ))}
            </select>
          </Stack>
        ))}
        <Button variant="contained" type="button" onClick={handleGuardar}>Guardar</Button>
      </form>
    </Stack>
  );
}

export default DataStorage;
